<template>
    <div>
        <CRow v-if="product && product._id">
            <CCol col="12">
                <CCard>
                    <CCardHeader>
                        Ürün Detayı
                    </CCardHeader>
                    <CCardBody>
                        <CAlert v-on:click="closeAlert()" v-if="alert.isOpen" show :color="alert.color">
                            {{ alert.message }}
                        </CAlert>
                        <CTabs>
                            <CTab title="Genel Bilgiler" active>
                                <CForm>
                                    <CInput
                                        label="Ürün ismi"
                                        horizontal
                                        autocomplete="productName"
                                        :value.sync="product.name"
                                    />
                                    <CInput
                                        label="Alt başlık"
                                        description="Ürün isminin yanındaki yazı. UNISEX gibi"
                                        horizontal
                                        :value.sync="product.subtitle"
                                    />
                                    <label>Ürün Açıklaması</label>
                                    <VueEditor
                                        v-model="product.description"
                                    />
                                    <CRow class="form-group" form>
                                        <CCol col="6">
                                            <CInput
                                                label="Yurtiçi fiyatı"
                                                horizontal
                                                :value.sync="product.price"
                                            />
                                            <CInput
                                                label="Sepette indirim"
                                                horizontal
                                                :value.sync="product.campaign.discount_percent"
                                                description="Yüzde olarak girilmelidir"
                                            />
                                        </CCol>
                                        <CCol col="6">
                                            <CInput
                                                label="Yurtdışı fiyatı"
                                                horizontal
                                                :value.sync="product.usd_price"
                                            />
                                            <CInput
                                                label="Sıra"
                                                horizontal
                                                :value.sync="product.order"
                                            />
                                        </CCol>
                                    </CRow>

                                    <CInput
                                        label="Kampanya Metni"
                                        horizontal
                                        :value.sync="product.campaign.text"
                                    />
                                    <CSelect
                                        label="Kategori"
                                        horizontal
                                        :value.sync="product.category"
                                        :options="productCategories"
                                        placeholder="Kategori seçiniz"
                                    />

                                    <CSelect
                                        label="Kategori"
                                        horizontal
                                        :value.sync="product.status"
                                        :options="productStatus"
                                        placeholder="Ürün Durumu"
                                    />

                                    <CRow form class="form-group">
                                        <CCol tag="label" sm="3" class="col-form-label">
                                            Ücretsiz Kargo
                                        </CCol>
                                        <CCol sm="9">
                                            <CSwitch
                                                class="mr-1"
                                                color="primary"
                                                :checked.sync="product.campaign.free_shipment"
                                            />
                                        </CCol>
                                    </CRow>

                                    <CButton
                                        type="button"
                                        size="sm"
                                        color="primary"
                                        style="float: right"
                                        v-on:click="update()"
                                    >
                                        <CIcon name="cil-check-circle"/> Kaydet
                                    </CButton>
                                </CForm>
                            </CTab>
                            <CTab title="Üretim">
                                <CForm>
                                    <CRow>
                                        <CCol col="6">
                                            <CInput
                                                label="Üretim ismi"
                                                :value.sync="newSerie.name"
                                            />
                                        </CCol>
                                        <CCol col="4">
                                            <CInput
                                                label="Parça başı maliyet"
                                                :value.sync="newSerie.cost"
                                            />
                                        </CCol>
                                        <CCol col="2">
                                            <CButton
                                                style="float: right; margin-top: 30px"
                                                type="button"
                                                size="sm"
                                                color="primary"
                                                v-on:click="addSerie()"
                                            >
                                                <CIcon name="cil-check-circle"/> Ekle
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                    <CRow v-for="serie in product.series" :key="serie._id">
                                        <CCol col="6">
                                            <CInput
                                                label="Üretim ismi"
                                                :value.sync="serie.name"
                                            />
                                        </CCol>
                                        <CCol col="2">
                                            <CInput
                                                label="Parça başı maliyet"
                                                :value.sync="serie.cost"
                                            />
                                        </CCol>
                                        <CCol col="1">
                                            <CButton
                                                style="float: right; margin-top: 30px"
                                                type="button"
                                                size="sm"
                                                color="danger"
                                                v-on:click="removeSerie(serie._id)"
                                            >
                                                <CIcon name="cil-delete"/> Sil
                                            </CButton>
                                        </CCol>
                                        <CCol col="2">
                                            <CButton
                                                style="float: right; margin-top: 30px"
                                                type="button"
                                                size="sm"
                                                color="primary"
                                                v-on:click="updateSerie(serie._id)"
                                            >
                                                <CIcon name="cil-check-circle"/> Kaydet
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CTab>
                            <CTab title="Bedenler">
                                <CForm>
                                    <CRow>
                                        <CCol col="12">
                                            <CSelect
                                                label="Üretim"
                                                horizontal
                                                :value.sync="selectedSerieIndex"
                                                :options="series"
                                                placeholder="Üretim seçiniz"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow v-if="selectedSerieIndex > -1">
                                        <CCol col="6">
                                            <CInput
                                                label="Beden ismi"
                                                :value.sync="newSize.name"
                                            />
                                        </CCol>
                                        <CCol col="4">
                                            <CInput
                                                label="Stok sayısı"
                                                :value.sync="newSize.count"
                                            />
                                        </CCol>
                                        <CCol col="2">
                                            <CButton
                                                style="float: right; margin-top: 30px"
                                                type="button"
                                                size="sm"
                                                color="primary"
                                                v-on:click="addSize()"
                                            >
                                                <CIcon name="cil-check-circle"/> Ekle
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                    <template v-if="selectedSerieIndex > -1">
                                        <CRow v-for="size in product.series[selectedSerieIndex].sizes" :key="size._id">
                                            <CCol col="6">
                                                <CInput
                                                    label="Beden ismi"
                                                    :value.sync="size.name"
                                                />
                                            </CCol>
                                            <CCol col="2">
                                                <CInput
                                                    label="Stok sayısı"
                                                    :value.sync="size.count"
                                                />
                                            </CCol>
                                            <CCol col="2">
                                                <CButton
                                                    style="float: right; margin-top: 30px"
                                                    type="button"
                                                    size="sm"
                                                    color="danger"
                                                    v-on:click="removeSize(size._id)"
                                                >
                                                    <CIcon name="cil-trash"/> Sil
                                                </CButton>
                                            </CCol>
                                            <CCol col="2">
                                                <CButton
                                                    style="float: right; margin-top: 30px"
                                                    type="button"
                                                    size="sm"
                                                    color="primary"
                                                    v-on:click="updateSize(size._id)"
                                                >
                                                    <CIcon name="cil-check-circle"/> Kaydet
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                    </template>
                                </CForm>
                            </CTab>
                            <CTab title="Fotoğraflar">
                                <CButton
                                    type="button"
                                    size="sm"
                                    color="primary"
                                    v-on:click="$refs.fileInput.click()"
                                >
                                    <CIcon name="cil-cloud-upload"/> Fotoğraf Yükle
                                </CButton>
                                <input type="file" class="hide" ref="fileInput" @change="fileSelected($event)" />

                                <CProgress v-if="isUploading" :value="uploadProgress" :max="100" show-percentage animated></CProgress>

                                <CRow v-for="item in product.files" :key="item.id" style="margin-top: 25px">
                                    <CCol col="3">
                                        <img :src="`https://rotaileshop.com/images/products/${item.preview}`" />
                                    </CCol>
                                    <CCol col="9">
                                        <CButton
                                            type="button"
                                            size="sm"
                                            color="danger"
                                            v-on:click="removeImage(item._id)"
                                        >
                                            <CIcon name="cil-delete"/> Fotoğrafı sil
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CTab>
                        </CTabs>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import axios from 'axios';
import { VueEditor } from 'vue2-editor';

import { getProductCategories, getProductStatus } from '../../utils';


export default {
    name: 'ProductDetail',

    components: {
        VueEditor
    },

    data () {
        return {
            alert: {
                isOpen: false,
                color: '',
                message: '',
            },
            product: {},
            newSize: {
                name: '',
                count: 0,
            },
            newSerie: {
                name: '',
                cost: 0
            },
            productCategories: getProductCategories(),
            productStatus: getProductStatus(),
            selectedSerieIndex: -1,
            series: [],
            uploadProgress: 0,
            isUploading: false,
        }
    },

    methods: {
        closeAlert() { this.alert.isOpen = false; },

        async update() {
	    let campaign = null;
            if (
              this.product.campaign &&
              (this.product.campaign.text || this.product.campaign.discount_percent || this.product.campaign.free_shipment)
            ) {
              campaign = {
                  text: this.product.campaign.text,
                  free_shipment: this.product.campaign.free_shipment,
                  discount_percent: Number(this.product.campaign.discount_percent.toString().replace(',', '.'))
              };
            }
            const data = {
                ...this.product,
                campaign: this.product.campaign && this.product.campaign.text ? {
                    text: this.product.campaign.text,
                    free_shipment: this.product.campaign.free_shipment,
                    discount_percent: Number(this.product.campaign.discount_percent.toString().replace(',', '.'))
                } : null,
                order: Number(this.product.order)
            };
            delete data.series;
            delete data.files;

            if (typeof data.price === 'string' && data.price.indexOf(',') > -1) {
                data.price = Number(data.price.toString().replace(',', '.'));
            }
            if (typeof data.usd_price === 'string' && data.usd_price.indexOf(',') > -1) {
                data.usd_price = Number(data.usd_price.toString().replace(',', '.'));
            }

            try {
                const response = await axios({
                    url: `/products/${this.product._id}`,
                    method: 'put',
                    data,
                });

                if (response && response.status > 199 && response.status < 300) {
                    this.product = response.data;
                    alert('Ürün başarıyla güncellendi');
                } else {
                    console.error(response.data);
                    this.alert.message = 'İşlem gerçekleştirilirken bir hata oluştu';
                    this.alert.isOpen = true;
                    this.alert.color = 'danger';
                }
            } catch (error) {
                console.error(error);
                this.alert.message = 'İşlem gerçekleştirilirken bir hata oluştu';
                this.alert.isOpen = true;
                this.alert.color = 'danger';
            }
        },

        async addSerie() {
            const response = await axios({
                method: 'post',
                url: `/products/${this.product._id}/series`,
                data: this.newSerie
            });
            this.newSerie = {
                name: '',
                cost: 0
            };
            this.product = response.data;
            this.series = this.product.series.map((item, index) => ({
                label: item.name,
                value: index
            }));


            if (response.status > 199 && response.status < 300) {
                this.alert = {
                    isOpen: true,
                    message: 'İşlem başarıyla tamamlandı',
                    color: 'success'
                }
            } else {
                this.alert = {
                    isOpen: true,
                    message: 'İşlem gerçekleştirilemedi',
                    color: 'danger'
                }
            }
        },

        async updateSerie(serieId) {
            const serie = this.product.series.find(x => x._id === serieId);
            if (!serie) { return; }

            const data = {
                ...serie
            };

            const { status, data: product } = await axios({
                method: 'put',
                url: `/products/${this.product._id}/series/${serieId}`,
                data
            });

            this.series = product.series.map((item, index) => ({
                label: item.name,
                value: index
            }));

            if (status > 199 && status < 300) {
                this.alert = {
                    isOpen: true,
                    message: 'İşlem başarıyla tamamlandı',
                    color: 'success'
                }
            } else {
                this.alert = {
                    isOpen: true,
                    message: 'İşlem gerçekleştirilemedi',
                    color: 'danger'
                }
            }
        },

        async removeSerie(serieId) {
            const { status, data } = await axios({
                method: 'delete',
                url: `/products/${this.product._id}/series/${serieId}`,
                data
            });
            this.product = data;
            this.series = this.product.series.map((item, index) => ({
                label: item.name,
                value: index
            }));

            if (status > 199 && status < 300) {
                this.alert = {
                    isOpen: true,
                    message: 'İşlem başarıyla tamamlandı',
                    color: 'success'
                }
            } else {
                this.alert = {
                    isOpen: true,
                    message: 'İşlem gerçekleştirilemedi',
                    color: 'danger'
                }
            }
        },

        async addSize() {
            const serie = this.product.series[this.selectedSerieIndex];
            const response = await axios({
                method: 'post',
                url: `/products/${this.product._id}/series/${serie._id}/sizes`,
                data: this.newSize
            });
            this.newSize = {
                name: '',
                cost: 0
            };
            this.product = response.data;
            this.series = this.product.series.map((item, index) => ({
                label: item.name,
                value: index
            }));
            if (response.status > 199 && response.status < 300) {
                this.alert = {
                    isOpen: true,
                    message: 'İşlem başarıyla tamamlandı',
                    color: 'success'
                }
            } else {
                this.alert = {
                    isOpen: true,
                    message: 'İşlem gerçekleştirilemedi',
                    color: 'danger'
                }
            }
        },

        async updateSize(sizeId) {
            const serie = this.product.series[this.selectedSerieIndex];
            const size = serie.sizes.find(x => x._id === sizeId);
            if (!serie) { return; }

            const { status } = await axios({
                method: 'put',
                url: `/products/${this.product._id}/series/${serie._id}/sizes/${sizeId}`,
                data: { ...size }
            });

            if (status > 199 && status < 300) {
                this.alert = {
                    isOpen: true,
                    message: 'İşlem başarıyla tamamlandı',
                    color: 'success'
                }
            } else {
                this.alert = {
                    isOpen: true,
                    message: 'İşlem gerçekleştirilemedi',
                    color: 'danger'
                }
            }
        },

        async removeSize(sizeId) {
            const serie = this.product.series[this.selectedSerieIndex];
            const { status, data } = await axios({
                method: 'delete',
                url: `/products/${this.product._id}/series/${serie._id}/sizes/${sizeId}`,
                data
            });
            this.product = data;
            this.series = this.product.series.map((item, index) => ({
                label: item.name,
                value: index
            }));

            if (status > 199 && status < 300) {
                this.alert = {
                    isOpen: true,
                    message: 'İşlem başarıyla tamamlandı',
                    color: 'success'
                }
            } else {
                this.alert = {
                    isOpen: true,
                    message: 'İşlem gerçekleştirilemedi',
                    color: 'danger'
                }
            }
        },

        async fileSelected(event) {
            const [file] = event.target.files;
            const data = new FormData()
            data.append('file', file);
            this.isUploading = true;
            try {
                const response = await axios({
                    url: `/products/${this.product._id}/files`,
                    method: 'post',
                    data,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progress) => {
                        this.uploadProgress = Math.round((100 * progress.loaded) / progress.total);
                    }
                });

                this.product = response.data;
            } catch (error) {
                console.error(error);
                alert('Dosya yüklenirken bir hata oluştu');
            }

            this.isUploading = false;
            this.uploadProgress = 0;
        },

        async removeImage(fileId) {
            const response = await axios({
                url: `/products/${this.product._id}/files/${fileId}`,
                method: 'delete'
            });

            if (response && response.status === 200) {
                this.product = response.data;
                alert('Fotoğraf başarıyla silindi!');
            } else {
                alert('İşlem gerçekleştirilirken bir hata oluştu!');
            }
        }
    },

    async mounted() {
        const response = await axios({
            method: 'get',
            url: `/products/${this.$route.params.id}`
        });
        this.product = response.data;
        this.series = this.product.series.map((item, index) => ({
            label: item.name,
            value: index
        }));
        if (!this.product.campaign) {
            this.product.campaign = {
                text: '',
                discount_percent: '',
                free_shipment: false
            };
        }
    }
}
</script>

<style lang="scss" scoped>
    label {
        margin-bottom: 15px;
    }

    img {
        max-width: 100%;
    }
</style>
